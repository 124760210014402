import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, authorizedGuard } from '@guard';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { HomeComponent } from './pages/home/home.component';
import { RouteTitles, RouteURI } from './router';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [authGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: RouteURI.APPLICATION_FORM },
      {
        path: RouteURI.APPLICATION_FORM,
        loadChildren: () => import('./pages/application/application.page.module').then(m => m.ApplicationPageModule),
        canActivate: [authorizedGuard],
      },
      {
        path: RouteURI.DEPARTMENT,
        loadChildren: () => import('./pages/department/department.page.module').then(m => m.DepartmentPageModule),
        canActivate: [authorizedGuard],
      },
      {
        path: RouteURI.SETTING,
        loadChildren: () => import('./pages/setting/setting.page.module').then(m => m.SettingPageModule),
        canActivate: [authorizedGuard],
      },
      {
        path: RouteURI.USER,
        loadChildren: () => import('./pages/user/user.page.module').then(m => m.UserPageModule),
        canActivate: [authorizedGuard],
      },
      {
        path: RouteURI.ROLE,
        loadChildren: () => import('./pages/role/role.page.module').then(m => m.RolePageModule),
        canActivate: [authorizedGuard],
      },
    ],
  },
  {
    path: RouteURI.LOGIN,
    title: RouteTitles.LOGIN,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: RouteURI.CHANGE_PASSWORD,
    title: RouteTitles.CHANGE_PASSWORD,
    component: ChangePasswordComponent,
    canActivate: [authGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
