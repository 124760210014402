import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { CommonService } from '@services';
import { finalize, Observable } from 'rxjs';

let totalRequests = 0;

export function LoadingInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  if (request.url.includes('search?')) {
    return next(request);
  }
  const commonService = inject(CommonService);
  totalRequests++;
  commonService.setLoading(true);
  return next(request).pipe(
    finalize(() => {
      totalRequests--;
      if (totalRequests == 0) {
        commonService.setLoading(false);
      }
    })
  );
}
