import { inject, isDevMode, NgModule, provideAppInitializer } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { NgOptimizedImage, registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptors, withJsonpSupport } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TitleStrategy } from '@angular/router';
import { GlobalIndicatorComponent, SwsLoadingSpinnerComponent } from '@components';
import * as allSwsIcons from '@icons';
import { Language } from '@model';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { Action, META_REDUCERS, provideStore, State, Store } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideTranslateService, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  ApplicationEffects,
  ApplicationFormEffects,
  AuthEffects,
  changeLanguage,
  CityEffects,
  DepartmentEffects,
  DocumentQuestionEffects,
  IAppState,
  MergedRouterStateSerializer,
  metaReducerFactory,
  reducers,
  RoleEffects,
  routerStateKey,
  selectCurrentLang,
  UserEffects,
} from '@store';
import { NzIconService } from 'ng-zorro-antd/icon';
import { take } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiIntercetpor } from './interceptors/api.interceptor';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { PageTitleStrategy } from './page-title.strategy';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { HomeComponent } from './pages/home/home.component';
import { SharedModule } from './shared/shared.module';

registerLocaleData(en);

function storeStateSanitizer(state: State<IAppState>) {
  // if you wanna hide the state
  return !isDevMode() ? {} : state;
}
function storeActionSanitizer(action: Action) {
  // for hide all actions payload but still wanna trace action dispatched
  return !isDevMode() ? { type: 'Prod' + action.type } : action;
}

const translateLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, '/assets/i18n/', '.json');

const initializeAppFn = () => {
  const iconService = inject(NzIconService);
  const translate = inject(TranslateService);
  const store = inject(Store<IAppState>);

  Object.values(allSwsIcons).forEach(({ icon, name }) => {
    iconService.addIconLiteral(name, icon);
  });
  translate.addLangs(['en', 'rke']);
  const currentLang: Language = (translate.getBrowserLang() as Language) || 'en';
  translate.setDefaultLang(currentLang);
  store
    .select(selectCurrentLang)
    .pipe(take(1), takeUntilDestroyed())
    .subscribe({
      next: lang => {
        if (lang) {
          translate.use(lang);
        } else {
          translate.use(currentLang);
          store.dispatch(changeLanguage({ lang: currentLang }));
        }
      },
    });
};

@NgModule({
  declarations: [AppComponent, HomeComponent, GlobalIndicatorComponent, ChangePasswordComponent, SwsLoadingSpinnerComponent],
  bootstrap: [AppComponent],
  imports: [NgOptimizedImage, BrowserModule, AppRoutingModule, SharedModule],
  providers: [
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([ApiIntercetpor, LoadingInterceptor]), withJsonpSupport()),
    { provide: TitleStrategy, useClass: PageTitleStrategy },
    provideRouterStore({
      stateKey: routerStateKey,
      serializer: MergedRouterStateSerializer,
    }),
    provideStore(reducers),
    {
      provide: META_REDUCERS,
      useFactory: metaReducerFactory,
      multi: true,
    },
    provideEffects(
      ApplicationEffects,
      AuthEffects,
      RoleEffects,
      UserEffects,
      CityEffects,
      DepartmentEffects,
      DocumentQuestionEffects,
      ApplicationFormEffects
    ),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      actionSanitizer: storeActionSanitizer,
      stateSanitizer: storeStateSanitizer,
      connectInZone: true,
    }),
    provideTranslateService({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    provideAppInitializer(initializeAppFn),
  ],
})
export class AppModule {}
