import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { IRole, Language, LANGUAGES, SWSMenuItem } from '@model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  changeLanguage,
  IAppState,
  logout,
  selectAuthorizedMenus,
  selectAuthUserRoles,
  selectCurrentAuthRole,
  selectLoginID,
} from '@store';
import { logoutConfirmModal } from '@util';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'sws-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: false,
})
export class HomeComponent implements OnInit {
  isCollapsed = true;

  principal$!: Observable<Record<string, unknown>>;
  currentLoginId$!: Observable<string>;
  currentRole$!: Observable<IRole>;
  authRoles$!: Observable<IRole[]>;
  title = 'ULA Member Registration System';

  languages = [...LANGUAGES];
  currentLang = this.translate.currentLang;

  screens: string[] = [];
  authorizedMenus$!: Observable<Array<SWSMenuItem>>;
  constructor(
    private readonly store: Store<IAppState>,
    private readonly destroyRef: DestroyRef,
    private readonly translate: TranslateService,
    private readonly modal: NzModalService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.currentLoginId$ = this.store.select(selectLoginID).pipe(takeUntilDestroyed(this.destroyRef));
    this.currentRole$ = this.store.select(selectCurrentAuthRole).pipe(takeUntilDestroyed(this.destroyRef));
    this.authorizedMenus$ = this.store.select(selectAuthorizedMenus).pipe(takeUntilDestroyed(this.destroyRef));
    this.authRoles$ = this.store.select(selectAuthUserRoles).pipe(takeUntilDestroyed(this.destroyRef));
  }

  onLogout() {
    logoutConfirmModal(this.modal, 'LOGOUT', 'Are you sure to logout?', () => this.store.dispatch(logout())!);
  }

  handleLanguageChange(lang: Language) {
    this.translate.use(lang);
    this.store.dispatch(changeLanguage({ lang }));
  }
}
