import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpHeaders, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@env';
import { ERROR_MESSAGES, MSGCode } from '@model';
import { Store } from '@ngrx/store';
import { clearAuthState, IAppState, selectAuthState } from '@store';
import { errorConfirmModal } from '@util';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { catchError, exhaustMap, Observable, take, throwError } from 'rxjs';

export function ApiIntercetpor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  let url = '';
  if (!request.url.includes('assets')) {
    url = `${environment.BASE_URI}` + request.url;
  }

  const store = inject(Store<IAppState>);
  const messageService = inject(NzMessageService);
  const modal = inject(NzModalService);

  let handler: ReturnType<HttpHandlerFn>;
  return store.select(selectAuthState).pipe(
    take(1),
    exhaustMap(({ token }) => {
      if (token) {
        const authHeader = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
        const cloned = request.clone({ headers: authHeader, url });
        handler = next(cloned);
      } else {
        const cloned = request.clone({ url });
        handler = next(cloned);
      }

      return handler.pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 403) {
            errorConfirmModal(modal, ERROR_MESSAGES[MSGCode.MSG_403], 'Resource is forbidden.');
          }

          if (url.includes('/logout')) {
            store.dispatch(clearAuthState());
            return throwError(() => err);
          }

          if (err.status === 401) {
            const { error } = err;
            const useModal = handleError(err);
            if (useModal && !url.includes('/logout')) {
              errorConfirmModal(modal, ERROR_MESSAGES[error.errors?.code as MSGCode], err.error?.message, () =>
                store.dispatch(clearAuthState())
              );
            } else {
              messageService.error(error.errors?.message || 'Something Went Wrong! Please try again.');
            }
          }

          return throwError(() => err);
        })
      );
    })
  );
}

const handleError = ({ error }: HttpErrorResponse): boolean => {
  if (error.errors?.code) {
    let useModal: boolean = false;
    switch (error.errors.code) {
      case '01':
      case '02':
        useModal = false;
        break;
      case '03':
      case '04':
      case '05':
      case '06':
      case '07':
      case '09':
        useModal = true;
        break;
      default:
        useModal = true;
        break;
    }
    return useModal;
  } else {
    return false;
  }
};
