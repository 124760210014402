<ng-template #formTitle>
  <div class="form-title">Change Password</div>
</ng-template>
<div class="card-wrapper">
  <nz-card class="sws-card" [nzTitle]="formTitle">
    <form nz-form nzLayout="vertical" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit($event)" novalidate>
      <nz-form-item>
        <nz-form-label nzFor="currentPassword" nzRequired>Current Password </nz-form-label>
        <nz-form-control [nzErrorTip]="currentPassErrorTpl">
          <nz-input-group [nzSuffix]="currentPassTpl" sws-input class="sws-password-input-group">
            <input
              [type]="currentPassVisible ? 'text' : 'password'"
              nz-input
              id="currentPassword"
              name="currentPassword"
              placeholder="Enter Current Password"
              formControlName="currentPassword" />
          </nz-input-group>
          <ng-template #currentPassTpl>
            <span
              nz-icon
              class="ant-input-password-icon"
              [nzType]="currentPassVisible ? 'eye-invisible' : 'eye'"
              (click)="currentPassVisible = !currentPassVisible"
              [attr.aria-hidden]="true"></span>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="newPassword" nzRequired>New Password </nz-form-label>
        <nz-form-control [nzErrorTip]="newPassErrorTpl">
          <nz-input-group [nzSuffix]="newPassTpl" sws-input class="sws-password-input-group">
            <input
              [type]="newPassVisible ? 'text' : 'password'"
              nz-input
              type="password"
              id="newPassword"
              name="newPassword"
              placeholder="Enter New Password"
              formControlName="newPassword" />
          </nz-input-group>
          <ng-template #newPassTpl>
            <span
              nz-icon
              class="ant-input-password-icon"
              [nzType]="newPassVisible ? 'eye-invisible' : 'eye'"
              (click)="newPassVisible = !newPassVisible"
              [attr.aria-hidden]="true"></span>
          </ng-template>
          @if (changePasswordForm.errors && changePasswordForm.errors['currentAndNewPasswordSame']) {
            <div class="ant-form-item-explain ant-form-item-explain-connected">
              <div role="alert" class="ant-form-item-explain-error" ng-reflect-ng-class="ant-form-item-explain-error">
                New password cannot be the same as the current password.
              </div>
            </div>
          }
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="confirmPassword" nzRequired>Confirm New Password </nz-form-label>
        <nz-form-control [nzErrorTip]="confirmPassError">
          <nz-input-group [nzSuffix]="confirmPassTpl" sws-input class="sws-password-input-group">
            <input
              [type]="confirmPassVisible ? 'text' : 'password'"
              nz-input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm New Password"
              formControlName="confirmPassword" />
          </nz-input-group>
          <ng-template #confirmPassTpl>
            <span
              nz-icon
              class="ant-input-password-icon"
              [nzType]="confirmPassVisible ? 'eye-invisible' : 'eye'"
              (click)="confirmPassVisible = !confirmPassVisible"
              [attr.aria-hidden]="true"></span>
          </ng-template>
          @if (changePasswordForm.errors && changePasswordForm.errors['newPasswordMismatch']) {
            <div class="ant-form-item-explain ant-form-item-explain-connected">
              <div role="alert" class="ant-form-item-explain-error" ng-reflect-ng-class="ant-form-item-explain-error">
                New password and confirm new password not match.
              </div>
            </div>
          }
        </nz-form-control>
      </nz-form-item>
      <sws-button-group class="sws-mt-2">
        <button type="submit" sws-button swsType="primary">Save</button>
        <button type="button" sws-button swsType="secondary" (click)="onCancel($event)">Cancel</button>
      </sws-button-group>
    </form>
  </nz-card>
</div>

<ng-template #currentPassErrorTpl let-control>
  @if (control.errors?.required) {
    Current password is required.
  } @else if (control.errors?.minlength) {
    Password must contain at least 12 characters.
  }
</ng-template>

<ng-template #newPassErrorTpl let-control>
  @if (control.errors?.required) {
    New password is required.
  } @else if (control.errors?.invalidPassword) {
    {{ passError }}
  }
</ng-template>

<ng-template #confirmPassError let-control>
  @if (control.errors?.required) {
    Confirm new password is required.
  } @else if (control.errors?.invalidPassword) {
    {{ passError }}
  }
</ng-template>
