import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState, changePassword } from '@store';
import { passwordValidator } from '@util';
import { passwordMismatchValidator } from 'src/app/utils/validator/password-mistmatch.validator';

@Component({
  selector: 'sws-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  standalone: false,
})
export class ChangePasswordComponent {
  changePasswordForm!: FormGroup;
  currentPassVisible = false;
  newPassVisible = false;
  confirmPassVisible = false;
  passError = `Password must contain at least 14 characters, one number, one uppercase letter, one lowercase letter, and one symbol
  (!@#$%^&*()-=_+[]{}|;':",./<>?).`;
  constructor(
    private builder: FormBuilder,
    private store: Store<IAppState>,
    private location: Location
  ) {
    this.changePasswordForm = this.builder.group(
      {
        currentPassword: [
          '',
          {
            validators: [Validators.required, Validators.minLength(12)],
            updateOn: 'blur',
          },
        ],
        newPassword: [
          '',
          {
            validators: [Validators.required, passwordValidator()],
            updateOn: 'blur',
          },
        ],
        confirmPassword: [
          '',
          {
            validators: [Validators.required, passwordValidator()],
            updateOn: 'blur',
          },
        ],
      },
      { validators: passwordMismatchValidator(), updateOn: 'blur' }
    );
  }

  onSubmit(event: SubmitEvent) {
    event.preventDefault();
    if (this.changePasswordForm.invalid) {
      Object.values(this.changePasswordForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity();
        }
      });
      return;
    } else {
      this.store.dispatch(changePassword({ data: { ...this.changePasswordForm.value } }));
      this.changePasswordForm.reset();
    }
  }

  onCancel(_event: Event) {
    this.location.back();
  }
}
