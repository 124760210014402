import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@env';

import { AppModule } from './app/app.module';

const bootstrap = platformBrowserDynamic().bootstrapModule(AppModule);
bootstrap
  .then(() => {
    if (environment.production) {
      console.log = function (): void {};
      console.debug = function (): void {};
      console.warn = function (): void {};
      console.info = function (): void {};
    }
  })
  .catch(err => console.error(err));
