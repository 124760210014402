<nz-sider
  class="menu-sidebar"
  nzCollapsible
  [nzWidth]="200"
  nzBreakpoint="md"
  [(nzCollapsed)]="isCollapsed"
  [nzCollapsedWidth]="76"
  [nzTrigger]="null">
  <div class="sws-aside-logo-wrapper">
    <a href="/" [style.width.px]="isCollapsed ? 64 : undefined">
      <img src="/assets/images/dashboard.svg" [alt]="title" />
    </a>
  </div>

  <ul nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed" [nzInlineIndent]="20">
    <!-- @for (menu of menusSub | async; track $index) {} -->
    @for (item of authorizedMenus$ | async; track $index) {
      @if (!item.submenu) {
        <li nz-menu-item nz-tooltip nzTooltipPlacement="right" nzMatchRouter [nzTooltipTitle]="isCollapsed ? item.title : ''">
          <a [routerLink]="item.uri">
            <nz-icon [nzType]="item.icon.type" [nzTheme]="item.icon.theme" />
            <span title>{{ item.title }}</span>
          </a>
        </li>
      } @else {
        <li nz-submenu [nzTitle]="item.title" [nzIcon]="item.icon.type">
          <ul>
            @for (sub of item.submenu; track idx; let idx = $index) {
              <li nz-menu-item nzMatchRouter>
                <a [routerLink]="sub.uri">
                  <nz-icon [nzType]="sub.icon.type" [nzTheme]="sub.icon.theme" />
                  <span title>{{ sub.title }}</span>
                </a>
              </li>
            }
          </ul>
        </li>
      }
    }
    <!-- @if ((currentRole$ | async)?.screens!.indexOf('Applications') > -1) {
      <li nz-menu-item nz-tooltip nzTooltipPlacement="right" nzMatchRouter [nzTooltipTitle]="isCollapsed ? 'Application Form' : ''">
        <a [routerLink]="routeUri.APPLICATION_FORM">
          <span nz-icon nzType="sws:pencil-square"></span><span title>Application Form</span>
        </a>
      </li>
    }
    @if ((currentRole$ | async)?.screens!.indexOf('Departments') > -1) {
      <li nz-menu-item nz-tooltip nzTooltipPlacement="right" nzMatchRouter [nzTooltipTitle]="isCollapsed ? 'Department' : ''">
        <a [routerLink]="routeUri.DEPARTMENT"><span nz-icon nzType="sws:diagram" nzTheme="fill"></span> <span title>Department</span></a>
      </li>
    }
    @if ((currentRole$ | async)?.screens!.indexOf('Users') > -1) {
      <li nz-menu-item nz-tooltip nzTooltipPlacement="right" nzMatchRouter [nzTooltipTitle]="isCollapsed ? 'User' : ''">
        <a [routerLink]="routeUri.USER"><span nz-icon nzType="sws:people" nzTheme="fill"></span> <span title>User</span></a>
      </li>
    }
    @if ((currentRole$ | async)?.screens!.indexOf('Roles') > -1) {
      <li nz-menu-item nz-tooltip nzTooltipPlacement="right" nzMatchRouter [nzTooltipTitle]="isCollapsed ? 'Role' : ''">
        <a [routerLink]="routeUri.ROLE"><span nz-icon nzType="sws:layers" nzTheme="fill"></span> <span title>Role</span></a>
      </li>
    } -->
    <!-- @if ((currentRole$ | async)?.screens!.indexOf('Settings') > -1) {
      <li nz-submenu nzTitle="Setting" nzIcon="sws:gear">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a [routerLink]="routeUri.SETTING">
              <span nz-icon nzType="file-protect" nzTheme="outline"></span>
              <span title>Doc Questions</span>
            </a>
          </li>
        </ul>
      </li>
     } -->
  </ul>
</nz-sider>

<!-- main -->
<nz-layout
  class="sws-right-layout"
  [ngStyle]="{
    'padding-left': isCollapsed ? '76px' : '200px',
    width: isCollapsed ? 'calc(100vw - 76px)' : 'calc(100vw - 200px)',
  }">
  <!-- header -->
  <nz-header>
    <nz-page-header [nzBackIcon]="null" [nzGhost]="false">
      <nz-page-header-title>
        <span class="collapsed-trigger" (click)="isCollapsed = !isCollapsed" [attr.aria-hidden]="true">
          <span nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'" nzTheme="outline"></span>
        </span>
        <span class="page-header-title">{{ title }}</span>
      </nz-page-header-title>
      <nz-page-header-extra>
        <nz-space [nzSize]="24">
          <nz-button-group *nzSpaceItem class="sws-current-user-group">
            <span class="sws-current-user">{{ currentLoginId$ | async }}</span>
            <button
              sws-button
              nz-dropdown
              type="button"
              nzType="text"
              nzTrigger="click"
              swsType="secondary"
              [swsBordered]="false"
              [nzDropdownMenu]="menu"
              nzPlacement="bottomRight"
              nzOverlayClassName="sws-principal-dropdown"
              class="sws-header-user">
              <span class="sws-person-icon" nz-icon nzType="sws:person-square"></span>
            </button>
          </nz-button-group>
          <nz-select
            *nzSpaceItem
            [nzShowSearch]="false"
            [nzShowArrow]="true"
            [nzOptions]="languages"
            [ngModel]="currentLang"
            (ngModelChange)="handleLanguageChange($event)"
            class="sws-select-lang" />
          <button
            *nzSpaceItem
            sws-button
            nz-tooltip
            type="button"
            [swsIconOnly]="true"
            [nzTooltipTitle]="'Logout'"
            [nzTooltipPlacement]="'bottomRight'"
            [swsBordered]="false"
            class="sws-btn-logout"
            (click)="onLogout()">
            <span class="sws-logout-icon" nz-icon nzType="sws:logout" nzTheme="outline"> </span>
          </button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>
  </nz-header>
  <!-- body -->
  <nz-content class="sws-content-container">
    <div class="sws-content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </nz-content>
</nz-layout>

<ng-template #dividerText>
  <nz-breadcrumb [nzAutoGenerate]="true" [nzSeparator]="separator"></nz-breadcrumb>
</ng-template>

<ng-template #separator>
  <span nz-icon nzType="caret-right" nzTheme="outline"></span>
</ng-template>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu [nzSelectable]="true">
    @for (item of authRoles$ | async; track item.id) {
      <li nz-menu-item [nzSelected]="item.code === (currentRole$ | async)?.code">{{ item.nameEN }}</li>
    }
    <li nz-menu-item>
      <a [routerLink]="['change-password']">
        {{ 'Change Password' }}
      </a>
    </li>
  </ul>
</nz-dropdown-menu>
