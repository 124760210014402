import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { CommonService } from '@services';

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private readonly commonService: CommonService
  ) {
    super();
  }

  override updateTitle(state: RouterStateSnapshot): void {
    const title = super.buildTitle(state);
    if (title !== undefined) {
      this.title.setTitle(`PMR :: ${title}`);
      this.commonService.setTitle(title);
    }
  }
}
